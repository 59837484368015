import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import 'rxjs/add/operator/toPromise';
@Injectable()
export class AccountSettingsCandidateService {
  private server_url_get_candidate_account = './server/server.php?cmd=GET_CANDIDATE_ACCOUNT';
  private server_url_confirm_candidate_account = './server/server.php?cmd=CONFIRM_CANDIDATE_ACCOUNT';
  private server_url_get_country_list = './server/server.php?cmd=LIST_COUNTRY';
  private server_url_get_candidate_session = './server/server.php?cmd=GET_CANDIDATE_SESSION';
  private server_url_get_md5_encrypt = './server/server.php?cmd=GET_MD5_ENCRYPTION';
  private server_url_update_account_candidate = './server/server.php?cmd=UPDATE_CANDIDATE';
  private server_url_destroy_candidate_session = './server/server.php?cmd=DESTROY_CANDIDATE_SESSION';
  private server_url_get_candidate_infos = './server/server.php?cmd=GET_CANDIDATE_INFOS';

  constructor(private http: Http) {

  }


  GetCandidateAccount(id: any): Promise<Object> {
    return this.http.get(this.server_url_get_candidate_account + '&id_account=' + id)
      .toPromise()
      .then(response => response.json() as Object)
      .catch(this.handleError);
  }

  ConfirmCandidateAccount(id: any): Promise<Object> {
    return this.http.get(this.server_url_confirm_candidate_account + '&id_account=' + id)
      .toPromise()
      .then(response => response.json() as Object)
      .catch(this.handleError);
  }

  GetCountryList(): Promise<Object> {
    return this.http.get(this.server_url_get_country_list)
      .toPromise()
      .then(response => response.json() as Object)
      .catch(this.handleError);
  }
  
  GetCandidateInfos(): Promise<Object> {
    return this.http.get(this.server_url_get_candidate_infos)
      .toPromise()
      .then(response => response.json() as Object)
      .catch(this.handleError);
  }


  GetCandidateSession() {
    return this.http.get(this.server_url_get_candidate_session)
      .toPromise()
      .then(response => response.json() as Object)
      .catch(this.handleError);
  }

  Md5Encryption(word: any): Promise<Object> {
    return this.http.get(this.server_url_get_md5_encrypt + '&word=' + word)
      .toPromise()
      .then(response => response.json() as Object)
      .catch(this.handleError);
  }

  UpdateAccountCandidate(
    id_candidate: string,
    name: string,
    firstname: string,
    password: string,
    mail: string,
    gender: string,
    birth_date: any,
    id_country: number,
    address: string,
    tel: string,
    active: string): Promise<Object> {
    return this.http.get(this.server_url_update_account_candidate + '&id_candidate=' + id_candidate + '&name=' + name + '&firstname=' + firstname + '&mail=' + mail + '&password=' + password + '&gender=' + gender + '&birth_date=' + birth_date + '&id_country=' + id_country + '&address=' + address + '&tel=' + tel + '&active=' + active)
      .toPromise()
      .then(response => response.json() as Object)
      .catch(this.handleError);
  }

  DestroyCandidateSession() {
    return this.http.get(this.server_url_destroy_candidate_session)
      .toPromise()
      .then(response => response.json() as Object)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }
}
