import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-content-pub',
  templateUrl: './content-pub.component.html',
  styleUrls: ['./content-pub.component.css']
})
export class ContentPubComponent implements OnInit {

  constructor(private router: Router) { }
DisplayAlert(){
     this.router.navigate(['/core/ui-candidate/alert']);
}
  ngOnInit() {
  }

}
