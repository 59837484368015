import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiCandidateRoutingModule} from './/ui-candidate-routing.module';
import {UiCandidateRootComponent} from './ui-candidate-root/ui-candidate-root.component';
import {PgHomeComponent} from './pg-home/pg-home.component';
import {PgAuthComponent} from './pg-auth/pg-auth.component';
import {PgBlogComponent} from './pg-blog/pg-blog.component';
import {HomeContentComponent} from './pg-home/home-content/home-content.component';
import {BlogContentComponent} from './pg-blog/blog-content/blog-content.component';
import {HeaderComponent} from './header/header.component';
import {BannerComponent} from './banner/banner.component';
import {FooterComponent} from './footer/footer.component';
import {MenuComponent} from './menu/menu.component';
import {ConnexionComponent} from './pg-auth/connexion/connexion.component';
import {DeconnexionComponent} from './pg-auth/deconnexion/deconnexion.component';
import {HttpModule} from '@angular/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  MatAutocompleteModule, MatButtonModule,
  MatButtonToggleModule, MatCardModule, MatCheckboxModule,
  MatChipsModule, MatDatepickerModule, MatDialogModule,
  MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule,
  MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule,
  MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
  MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule,
  MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatTableModule,
  MatTabsModule, MatToolbarModule, MatTooltipModule
} from '@angular/material';

import {ContentOfferComponent} from './pg-home/home-content/content-offer/content-offer.component';
import {ContentSearchComponent} from './pg-home/home-content/content-search/content-search.component';
import {ContentPubComponent} from './pg-home/home-content/content-pub/content-pub.component';
import {AccountCandidateComponent} from './pg-home/account-candidate/account-candidate.component';
import {UiCandidateContentComponent} from './pg-home/ui-candidate-content/ui-candidate-content.component';
import {PgDetailComponent} from './pg-detail/pg-detail.component';
import {PgDetailArticleComponent} from './pg-detail-article/pg-detail-article.component';
import {PgAccountComponent} from './pg-account/pg-account.component';
import {PgAlertComponent} from './pg-alert/pg-alert.component';
import {PgAccountSettingsComponent} from './pg-account-settings/pg-account-settings.component';
import {PgAccountSettingsInfosComponent} from './pg-account-settings/pg-account-settings-infos.component';
import {PgAccountSettingsExperienceComponent} from './pg-account-settings/pg-account-settings-experience.component';


@NgModule({
  imports: [
    CommonModule,
    UiCandidateRoutingModule,
    HttpModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule, MatButtonModule,
    MatButtonToggleModule, MatCardModule, MatCheckboxModule,
    MatChipsModule, MatDatepickerModule, MatDialogModule,
    MatExpansionModule, MatGridListModule, MatIconModule, MatInputModule,
    MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule,
    MatProgressBarModule, MatProgressSpinnerModule, MatRadioModule,
    MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule,
    MatSlideToggleModule, MatSnackBarModule, MatSortModule, MatTableModule,
    MatTabsModule, MatToolbarModule, MatTooltipModule
  ],
  declarations: [PgAccountSettingsExperienceComponent, PgAccountSettingsInfosComponent, PgAccountComponent, UiCandidateRootComponent, PgHomeComponent, PgAuthComponent, PgBlogComponent, HomeContentComponent, BlogContentComponent, HeaderComponent, BannerComponent, FooterComponent, MenuComponent, ConnexionComponent, DeconnexionComponent, ContentOfferComponent, ContentSearchComponent, ContentPubComponent, AccountCandidateComponent, UiCandidateContentComponent, PgDetailComponent, PgDetailArticleComponent, PgAlertComponent, PgAccountSettingsComponent]
})
export class UiCandidateModule {}
