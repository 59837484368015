import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-candidate-root',
  templateUrl: './ui-candidate-root.component.html',
  styleUrls: ['./ui-candidate-root.component.css']
})
export class UiCandidateRootComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
