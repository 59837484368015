import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ui-candidate-content',
  templateUrl: './ui-candidate-content.component.html',
  styleUrls: ['./ui-candidate-content.component.css']
})
export class UiCandidateContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
