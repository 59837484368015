import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {ActivatedRoute, Params} from '@angular/router';
import {PgBlogService} from './../pg-blog/pg-blog.service';

@Component({
  selector: 'app-pg-detail-article',
  templateUrl: './pg-detail-article.component.html',
  styleUrls: ['./pg-detail-article.component.css'],
  providers:[PgBlogService]
})
export class PgDetailArticleComponent implements OnInit {
list_article: any;
  constructor(private activated_route :ActivatedRoute, private blog_service : PgBlogService,private router: Router) { }
  
  
  GetArticleDetail(){
      this.activated_route.params.subscribe((params: Params) => {
            let id_article = params['id_article'];            
            this.blog_service.GetArticleById(id_article).then(result => {
                this.list_article = result['list'][0];
                console.log( this.list_article  );
            });
        });
  }
 Return() {
        this.router.navigate(['/core/ui-candidate/blog']);
        console.log("tonga")
    }
  ngOnInit() {
      this.GetArticleDetail();
  }

}
