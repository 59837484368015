import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pg-blog',
  templateUrl: './pg-blog.component.html',
  styleUrls: ['./pg-blog.component.css']
})
export class PgBlogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
