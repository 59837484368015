import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import 'rxjs/add/operator/toPromise';
@Injectable()
export class AccountCandidateService {
    private server_url_create_account_candidate = './server/server.php?cmd=INSERT_CANDIDATE';
    private server_url_get_country_list = './server/server.php?cmd=LIST_COUNTRY';
    constructor(private http: Http) {

    }


    CreateAccountCandidate(name: string, firstname: string, password: string, mail: string, gender: string, birth_date: any, id_country: number, address: string, tel: string): Promise<Object> {
        return this.http.get(this.server_url_create_account_candidate + '&name=' + name + '&firstname=' + firstname + '&mail=' + mail + '&password=' + password + '&gender=' + gender + '&birth_date=' + birth_date + '&id_country=' + id_country + '&address=' + address + '&tel=' + tel)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }

    GetCountryList(): Promise<Object> {
        return this.http.get(this.server_url_get_country_list)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }
}
