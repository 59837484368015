import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {OfferService} from './../pg-home/home-content/content-offer/offer.service';
import {Router} from '@angular/router';
@Component({
    selector: 'app-pg-detail',
    templateUrl: './pg-detail.component.html',
    styleUrls: ['./pg-detail.component.css'],
    providers: [OfferService]
})
export class PgDetailComponent implements OnInit {
    list_offer_detail: any;
    number_page: any;
    id_offer: any;
    constructor(private activated_route: ActivatedRoute, private offer_service: OfferService, private router: Router) {}

    GetDetailOffer() {
        this.activated_route.params.subscribe((params: Params) => {
            // get parameter
            this.id_offer = +params['id'];
            this.number_page = params['page'];
            this.offer_service.GetOfferListById(this.id_offer).then(result => {
                                console.log('ato: '+result['result']);
                if (result['result'] === 'OK') {
                    this.list_offer_detail = result['list'][0];
                } else {
                    this.router.navigate(['/core/ui-candidate/home']);
                }

            });
        });
    }

    Return() {

        this.router.navigate(['/core/ui-candidate/home', {page: this.number_page}]);
    
    }

    ngOnInit() {
        this.GetDetailOffer();
    }

}
