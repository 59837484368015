import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {CoreRootComponent} from './core-root/core-root.component';

const coreRoutes: Routes = [
    {
        path: 'core', component: CoreRootComponent, children: [
            {path: '', redirectTo: 'ui-candidate', pathMatch: 'full'},
            //      {path: 'ui-candidate', loadChildren: 'app/ui-candidate/ui-candidate.module#UiCandidateModule'},
            //      {path: 'ui-recruiter', loadChildren: 'app/ui-recruiter/ui-recruiter.module#UiRecruiterModule'},
            {path: 'ui-candidate', loadChildren: '../ui-candidate/ui-candidate.module#UiCandidateModule'},
            {path: 'ui-recruiter', loadChildren: '../ui-recruiter/ui-recruiter.module#UiRecruiterModule'},
        ]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(coreRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CoreRoutingModule {}
