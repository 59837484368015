import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {PgBlogService} from './../pg-blog.service';
@Component({
  selector: 'app-blog-content',
  templateUrl: './blog-content.component.html',
  styleUrls: ['./blog-content.component.css'],
  providers : [PgBlogService]
})
export class BlogContentComponent implements OnInit {
article_list : any;
    constructor(private router: Router, private blog_service:PgBlogService)
   { 
   }
   
   GetArticleList(){
        this.blog_service.GetArticleList().then(result => {
            this.article_list = result['list'];
            console.log(this.article_list);
            });
    }
   
 DisplayDetailArticle(id_article: string) {
        this.router.navigate(['/core/ui-candidate/article-detail', {id_article:id_article}]);
    }
  ngOnInit() {
      this.GetArticleList();
  }

}
