import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AccountSettingsCandidateService} from './account-settings-candidate.service';
//import {PgAuthComponent} from '../pg-auth/pg-auth.component';

@Component({
  selector: 'app-pg-account-settings-experience',
  templateUrl: './pg-account-settings-experience.component.html',
  styleUrls: ['./pg-account-settings-experience.component.css'],
  providers: [AccountSettingsCandidateService]
})
export class PgAccountSettingsExperienceComponent implements OnInit {
  panelOpenState = false;
  
  experiences: any = [];
  formations:any = [];
  
  
  constructor(private account_candidate_service: AccountSettingsCandidateService) {
  }

  ngOnInit() {
    this.GetCandidateInfos();
  }


  GetCandidateInfos(): void {
    this.account_candidate_service.GetCandidateInfos().then(result => {
      for(let i = 0; i< result['list'].length; i++){
        if(result['list'][i]['type'] === 'EXPERIENCE'){
          this.experiences.push(result['list'][i]);
         // console.log('EXPERIENCES');
        }else{
        this.formations.push(result['list'][i]);
         // console.log('FORMATIONS');
        }
      }
      console.log(this.experiences);
      console.log(this.formations);
      //console.log(result);
    });
  }

}
