import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {UiCandidateRootComponent} from './ui-candidate-root/ui-candidate-root.component';
import {PgHomeComponent} from './pg-home/pg-home.component';
import {PgBlogComponent} from './pg-blog/pg-blog.component';
import {PgAuthComponent} from './pg-auth/pg-auth.component';
import {PgDetailComponent} from './pg-detail/pg-detail.component';
import {PgDetailArticleComponent} from './pg-detail-article/pg-detail-article.component';
import {HomeContentComponent} from './pg-home/home-content/home-content.component';
import {PgAccountComponent} from './pg-account/pg-account.component';
import {HeaderComponent} from './header/header.component';
import {PgAlertComponent} from './pg-alert/pg-alert.component';
import {PgAccountSettingsComponent} from './pg-account-settings/pg-account-settings.component';
import {PgAccountSettingsInfosComponent} from './pg-account-settings/pg-account-settings-infos.component';
import {PgAccountSettingsExperienceComponent} from './pg-account-settings/pg-account-settings-experience.component';

const uiCandidateRoutes: Routes = [
  {
    path: '', component: UiCandidateRootComponent, children: [
      {path: '', redirectTo: 'home', pathMatch: 'full'},
      {path: 'home', component: PgHomeComponent},
      {path: 'blog', component: PgBlogComponent},
      {path: 'auth', component: PgAuthComponent},
      //            {path: 'detail', component: PgDetailComponent},// à modifier definition
      {path: 'detail/:id/:page', component: PgDetailComponent},// à modifier definition
      {path: 'account', component: PgAccountComponent},
      {path: 'article-detail', component: PgDetailArticleComponent},
      {path: 'alert', component: PgAlertComponent},
      //{path: 'account-settings', component: PgAccountSettingsComponent}
      {
        path: 'account-settings', component: PgAccountSettingsComponent, children: [
          {
                path:'',
                component: PgAccountSettingsInfosComponent,
//                redirectTo: 'x',
                pathMatch: 'full' 
          },
          {path: 'link_1', component: PgAccountSettingsInfosComponent},
          {path: 'link_2', component: PgAccountSettingsExperienceComponent},
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(uiCandidateRoutes)
  ],
  exports: [
    RouterModule,
    //    BrowserAnimationsModule,
    // NoopAnimationsModule
  ]
})
export class UiCandidateRoutingModule {}
