import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {trigger, state, transition, style, animate} from '@angular/animations'
import {ConnexionService} from './connexion.service';
//import {Router} from '@angular/router';
@Component({
    selector: 'app-connexion',
    templateUrl: './connexion.component.html',
    styleUrls: ['./connexion.component.css'],
    providers: [ConnexionService]
})
export class ConnexionComponent implements OnInit {
    candidate_login: string;
    candidate_password: string;
    connexion_control: FormGroup;
    connected: boolean = false;

    constructor(private fb: FormBuilder, private connexion_service: ConnexionService) {
        this.CreateForm();
    }

    VerifyCandidateAuth() {
        if (this.connexion_control.status === 'VALID') {
            this.connexion_service.VerifyCandidateAuth(this.candidate_login, this.candidate_password).then(result => {
                if (result['result'] === 'OK') {
                    this.connected = true;
             
                } else {
                 this.connected = false;
                }
                  //    alert(this.connected );
               //   this.router.navigate(['auth']);

            });
        }

    }

    CreateForm() {
        this.connexion_control = this.fb.group({
            login_ctrl: ['', Validators.required],
            password_ctrl: ['', Validators.required]

        });
    }



    ngOnInit() {
    }

}
