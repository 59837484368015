import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {ContentSearchComponent} from './content-search/content-search.component';
import {ContentOfferComponent} from './content-offer/content-offer.component';
import {OfferService} from './content-offer/offer.service';
@Component({
    selector: 'app-home-content',
    templateUrl: './home-content.component.html',
    styleUrls: ['./home-content.component.css'],
    providers: [OfferService]

})
export class HomeContentComponent implements OnInit {

    constructor(private offer_service: OfferService) {
        this.offer_service.to_pg_home$.subscribe(
            data => {
                    this.HideFilterOffer = data;

            });
    }
    HideContentOffer: boolean = true;
    HideFilterOffer: boolean = false;
    ngOnInit() {
    }

}
