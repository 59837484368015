import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AccountSettingsCandidateService} from './account-settings-candidate.service';
//import {PgAuthComponent} from '../pg-auth/pg-auth.component';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {DateAdapter, NativeDateAdapter} from '@angular/material';

@Component({
  selector: 'app-pg-account-settings-infos',
  templateUrl: './pg-account-settings-infos.component.html',
  styleUrls: ['./pg-account-settings-infos.component.css'],
  providers: [AccountSettingsCandidateService, DatePipe]
})
export class PgAccountSettingsInfosComponent implements OnInit {
  
  display: boolean = true;
  
  candidate_id: string;
  candidate_firstname: string;
  candidate_name: string;
  candidate_login: string;
  candidate_password: string;
  latest_password: string;
  candidate_mail: string;
  candidate_gender: string;
  candidate_birth_date: any;
  country: any;
  candidate_address: string;
  candidate_tel: string;
  candidate_id_country: number;
  candidate_is_active: string;
  confirm_candidate_password: string;
  indication_message: string = "";
  show_message: boolean = false;
  form_account_candidate: FormGroup;
  showForm: boolean = true;
  password_ok: boolean = false;
  country_list: any;
  date_modif: Array<Date> = [];


  constructor(private router: Router, private account_candidate_service: AccountSettingsCandidateService, private fb: FormBuilder, private datepipe: DatePipe, dateAdapter: DateAdapter<NativeDateAdapter>) {
    this.CreateForm();
    dateAdapter.setLocale('fr-FR');

  }

  UpdateAccountCandidate() {
    console.log(this.confirm_candidate_password)
    if (this.confirm_candidate_password !== "" && this.candidate_password !== "") {
      this.Md5EncryptMdp(this.candidate_password);
    } else {
      this.FinaliseUpdateAccountCandidate();
    }
    /*var date_line = (this.datepipe.transform(this.candidate_birth_date, 'dd-MM-yyyy'));
    */
  }

  FinaliseUpdateAccountCandidate() {
    var date_line = (this.datepipe.transform(this.candidate_birth_date, 'dd-MM-yyyy'));

    //    if ((this.form_account_candidate.status === 'VALID') && (this.password_ok)) {
    this.account_candidate_service.UpdateAccountCandidate(this.candidate_id, this.candidate_name, this.candidate_firstname, this.confirm_candidate_password, this.candidate_mail, this.candidate_gender, date_line, this.country.id_country, this.candidate_address, this.candidate_tel, this.candidate_is_active).then(result => {

              if (result['result'] === 'OK') {
        if (result['need_reconnection'] === true) {
          this.display = false;
          this.indication_message = "Une reconnexion sur votre compte sera nécessaire, déconnexion en couirs...";
          setTimeout(() => {this.show_message = false; this.Deconnexion();}, 5000);
        } else {
          let element = result['element'][0];
          this.SetCandidateInfos(element);
          this.indication_message = "Modification de vos informations avec succès!";
        }
        this.show_message = true;
        setTimeout(() => {this.show_message = false;}, 7000);
        // this.Reset();
      }

    });

  }

  Md5EncryptMdp(word: string): void {
    this.account_candidate_service.Md5Encryption(word).then(result => {
      if (result['result'] === 'OK') {
        console.log(this.latest_password);
        console.log(result['word']);
        if (this.latest_password === result['word']) {
          this.password_ok = true;
          this.FinaliseUpdateAccountCandidate();
        } else {
          this.password_ok = false;
          this.indication_message = 'Ancien mot de passe non valide!';
          this.show_message = true;
          setTimeout(() => {this.show_message = false;}, 2000);
        }
      }
    });
  }


  GetCandidateSession() {
    this.account_candidate_service.GetCandidateSession().then(result => {
      if (result['result'] === 'OK') {
        let element = result['list'][0];
        this.SetCandidateInfos(element);
      } else {

      }
    });
  }

  SetCandidateInfos(element: any): void {
    this.candidate_id = element.id_candidate;
    this.candidate_firstname = element.firstname;
    this.candidate_name = element.name;
    this.latest_password = element.password;
    this.candidate_password = "";
    this.confirm_candidate_password = "";
    this.candidate_mail = element.mail;
    this.candidate_gender = element.gender;
    this.candidate_is_active = element.active;
    this.candidate_birth_date = new Date();

    for (let i = 0; i < this.country_list.length; i++) {
      if (parseInt(this.country_list[i].id_country) === parseInt(element.id_country)) {
        this.country = this.country_list[i];
      }
    }

    const datePipe = new DatePipe('en-US');
    const myFormattedDate = datePipe.transform(element.birth_date * 1000, 'dd/MM/yyyy');
    this.candidate_birth_date = new Date(element.birth_date * 1000);

    this.candidate_address = element.address;
    this.candidate_tel = element.tel;
    this.candidate_id_country = element.id_country;
  }

  GetCountryList() {
    this.account_candidate_service.GetCountryList().then(result => {
      this.country_list = result['list'];
      this.GetCandidateSession();
    });
  }

  CancelCreateAccount() {
    this.Reset();
    this.show_message = false;
  }

  Reconnection(): void {
  }

  Deconnexion() {
    this.account_candidate_service.DestroyCandidateSession().then(result => {
      if (result['result'] === 'OK') {
        this.router.navigate(['/home']);
      } else {

      }

    });
  }


  CreateForm() {
    this.form_account_candidate = this.fb.group({
      candidate_name_ctrl: ['', Validators.required],
      candidate_firstname_ctrl: ['', Validators.required],
      candidate_password_ctrl: ['', Validators.required],
      confirm_candidate_password_ctrl: ['', Validators.required],
      candidate_email_ctrl: ['', Validators.required],

      //            candidate_email_ctrl: ['', Validators.compose([Validators.required, Validators.pattern('^[^\s@]+@[^\s@]+\.[^\s@]{2,}$'), Validators.minLength(1)])],
      candidate_gender_ctrl: ['', Validators.required],
      candidate_birth_date_ctrl: ['', Validators.required],
      candidate_id_country_ctrl: ['', Validators.required],
      candidate_address_ctrl: ['', Validators.required],
      candidate_tel_ctrl: ['', Validators.required]
    });


  }

  Reset(): void {
    this.form_account_candidate.reset();
    this.showForm = false;
    setTimeout(() => {
      this.CreateForm();
      this.showForm = true;
    });
  }



  ngOnInit() {
    this.GetCountryList();
  }

}
