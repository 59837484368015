import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {AccountSettingsCandidateService} from './account-settings-candidate.service';
//import {PgAuthComponent} from '../pg-auth/pg-auth.component';

@Component({
  selector: 'app-pg-account-settings',
  templateUrl: './pg-account-settings.component.html',
  styleUrls: ['./pg-account-settings.component.css'],
  providers: [AccountSettingsCandidateService]
})
export class PgAccountSettingsComponent implements OnInit {

  msg_infos: string;
  id_candidate_account: number;
  candidate_name: string;
  candidate_gender: string;
  account_confirmation: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
    private account_candidate_service: AccountSettingsCandidateService,
//    private pg_auth: PgAuthComponent
  ) {
   // this.pg_auth.connected = true;
    //this.pg_auth.GetCandidateSession();
    this.GetUrlAccountParams();
  }

  GetUrlAccountParams(): void {
    // subscribe to router event
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log(params.registration);

      if (params.registration === undefined) {
        this.account_candidate_service.GetCandidateAccount(params.registration).then(result => {
          //console.log(result);
          if (result['result'] === 'OK') {
            this.id_candidate_account = result['candidate_infos']['id_candidate'];
            this.candidate_name = result['candidate_infos']['firstname'] + ' ' + result['candidate_infos']['name'];
            this.candidate_gender = 'M';
            if (result['candidate_infos']['gender'] === 'F') {
              this.candidate_gender = 'Mme';
            }
            if (parseInt(result['candidate_infos']['active']) === 0) {
              this.ConfirmAccount();
              //this.msg_infos = 'Bonjour ' + this.candidate_gender + ' ' + this.candidate_name + ', pour finaliser la création de votre compte, veuillez cliquer sur le bouton ci-dessous.';
            } else if (parseInt(result['candidate_infos']['active']) === 1) {
              this.account_confirmation = true;
              this.msg_infos = 'Cet lien n \'est plus valide';
            }
          }
        });
      } else {

      }


    });
  }



  ConfirmAccount(): void {
    this.account_candidate_service.ConfirmCandidateAccount(this.id_candidate_account).then(result => {
      if (result['result'] === 'OK') {
        this.id_candidate_account = result['candidate_infos'][0]['id_candidate'];
        this.candidate_name = result['candidate_infos']['firstname'] + ' ' + result['candidate_infos']['name'];
        this.account_confirmation = true;
        this.candidate_gender = 'M';
        if (result['candidate_infos']['gender'] === 'F') {
          this.candidate_gender = 'Mme';
        }
        this.msg_infos = 'Félicitation ' + this.candidate_gender + ' ' + this.candidate_name + ', votre compte a été bien créé.';
      }
    });
  }

  ngOnInit() {
  }

}
