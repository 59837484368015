import {Component, Input, OnInit, OnChanges, EventEmitter, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OfferService} from './offer.service';
import {ObjectOffer} from './../object-offer';
import {Router} from '@angular/router';
import {ActivatedRoute, Params} from '@angular/router';

@Component({
    selector: 'app-content-offer',
    templateUrl: './content-offer.component.html',
    styleUrls: ['./content-offer.component.css'],
    providers: []

})
export class ContentOfferComponent implements OnInit, OnChanges {


    searchCaseNumber: any = '';
    HideContentOffer: boolean = true;
    data: string[] = [];
    //    affiche_next: any = 'none';
    list_offer: any;
    sheet_number: any = 0;
    list_lenght: any;
    active_page: any;
    display_table: boolean = true;
    affiche_next: any = 'block';
    hide: any = 'block';
    font_color: any = 'white';
    j: number = 3;

    // list_page: <Array>();
    list_page = [];
    display_logo: boolean = true;

    object_list: ObjectOffer = {
        id_work_contract: 0,
        id_work_field: 0,
        array_city: [2, 0, 0, 0, 0, 0],
        start_date: '',
        salary: '',
        keyword: ''
    };
    page_callback_detail: any = -1;
    pg_home_variable = true;

    list_offer_filter = [];
    show_detail_offer: boolean = true;
    constructor(private activated_route: ActivatedRoute, private fb: FormBuilder, private offer_service: OfferService, private router: Router) {

        this.offer_service.object_filter$.subscribe(
            data => {
                if (data === 1) {
                    this.HideContentOffer = true;
                }
                else {
                    this.object_list.id_work_contract = data.id_work_contract;
                    this.object_list.id_work_field = data.id_work_field;
                    this.object_list.array_city = data.array_city;
                    this.object_list.start_date = data.start_date;
                    this.object_list.salary = data.salary;
                    this.object_list.keyword = data.keyword;
                    this.FilterOffer(this.object_list);
                }
            });
    }


    DetailOfferCallBack() {
        this.activated_route.params.subscribe((params: Params) => {
            this.page_callback_detail = params['page'];
        });
        if (this.page_callback_detail) {
            this.DisplayPage(this.page_callback_detail);

        }
    }
    GetOfferList() {

        this.offer_service.GetOfferList()
            .then(result => {

                this.DisplayOffer(result['list']);
            });
    }
    FilterOffer(filter_object: ObjectOffer) {
        this.offer_service.FilterOffer(filter_object)
            .then(result => {
                this.list_offer_filter = [];
                this.list_offer_filter = result['list'];
                this.DisplayOffer(this.list_offer_filter);
            });
    }
    DisplayOffer(list_offer: any): void {
        this.list_offer = list_offer;
        this.list_lenght = this.list_offer.length;
        this.list_page[0] = {indice: 1, color: '#41C5DA'};
        if (this.page_callback_detail > 0) {
            this.list_page[0] = {indice: 1, color: '#03484D'};

        }
        for (let i = 1; i < this.list_lenght; i++) {
            if (i === parseInt(this.page_callback_detail)) {
                this.list_page[i] = {indice: i + 1, color: '#41C5DA'};
            }
            else {
                this.list_page[i] = {indice: i + 1, color: '#03484D'};
            }
        }

    }

    DisplayDetail(id_offer) {
        // envoie detail offre
      console.log('DISPLAY DETAIL: ' + id_offer + ' - ' + this.sheet_number);
        this.router.navigate(['/core/ui-candidate/detail', id_offer, this.sheet_number]);
    }

    RemoveElement() {
        var removed_elements = this.list_offer.splice(0, this.list_lenght);
    }

    DisplayPage(id) {
        this.sheet_number = id;

        this.list_page = [];
        for (let i = 0; i < this.list_lenght; i++) {
            if (i < this.j) {
                if (i === parseInt(this.sheet_number)) {
                    this.list_page[i] = {indice: i + 1, color: '#41C5DA'};
                } else {
                    this.list_page[i] = {indice: i + 1, color: '#03484D'};
                }
            }
        }
    }
    DisplayNextPage() {

        if (this.sheet_number < this.list_lenght - 1) {
            this.sheet_number = this.sheet_number + 1;
            for (let i = 0; i < this.list_lenght; i++) {
                if (i < this.j) {
                    if (i === parseInt(this.sheet_number)) {
                        this.list_page[i] = {indice: i + 1, color: '#41C5DA'};
                    } else {
                        this.list_page[i] = {indice: i + 1, color: '#03484D'};
                    }
                }
            }
        }
        else {
            this.sheet_number = this.sheet_number;

        }
    }

    DisplayPrevPage() {
        if (this.sheet_number > 0) {
            this.sheet_number = this.sheet_number - 1;
            for (let i = 0; i < this.list_lenght; i++) {
                if (i < this.j) {
                    if (i === parseInt(this.sheet_number)) {
                        this.list_page[i] = {indice: i + 1, color: '#41C5DA'};
                    } else {
                        this.list_page[i] = {indice: i + 1, color: '#03484D'};
                    }
                }
            }
        }
        else {
            this.sheet_number = this.sheet_number;
        }
    }


    ngOnChanges() {

    }

    ngOnInit() {
        this.list_offer = [];
        this.GetOfferList()      //   this.FilterOffer(this.object_list);
        this.DetailOfferCallBack();
    }
}