import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import 'rxjs/add/operator/toPromise';
import {Subject} from 'rxjs/Subject';
import {ObjectOffer} from './../object-offer';
@Injectable()
export class OfferService {
    private server_url_list_offer = './server/server.php?cmd=LIST_OFFER';
    private server_url_search_by_work_contract = './server/server.php?cmd=SEARCH_BY_WORK_CONTRACT';
    private server_url_city_list = './server/server.php?cmd=LIST_CITY';
    private server_url_work_field_list = './server/server.php?cmd=LIST_WORK_FIELD';
    private server_url_work_contract_list = './server/server.php?cmd=LIST_WORK_CONTRACT';
    private server_url_salary_list = './server/server.php?cmd=LIST_SALARY';
    private server_url_list_offer_by_id = './server/server.php?cmd=GET_LIST_OFFER_BY_ID';
    search_parameter: string;

    constructor(private http: Http) {

    }

    //    private object_filter = new Subject<string>();
    private object_filter = new Subject<any>();
    object_filter$ = this.object_filter.asObservable();

    private to_pg_home = new Subject<any>();
  to_pg_home$ = this.to_pg_home.asObservable();

    publishData(data: any) {
        this.object_filter.next(data);
    }

    publishDataPgHome(pg_home: any) {
        this.to_pg_home.next(pg_home);
    }


    GetOfferList(): Promise<Object> {
        this.search_parameter = 'without_parameter';
        return this.http.get(this.server_url_list_offer + '&search_parameter=' + this.search_parameter)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }
    
    
    GetOfferListById(id_offer : any): Promise<Object> {
    
        return this.http.get(this.server_url_list_offer_by_id + '&id_offer=' + id_offer)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }
    FilterOffer(object_filter: ObjectOffer): Promise<Object> {
        this.search_parameter = 'with_parameter';
        return this.http.get(this.server_url_list_offer + '&search_parameter=' + this.search_parameter + '&id_work_contract=' + object_filter.id_work_contract + '&id_work_field=' + object_filter.id_work_field + '&date_start=' + object_filter.start_date + '&id_salary=' + object_filter.salary + '&keyword=' + object_filter.keyword + '&id_city=' + object_filter.array_city)

            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }


    GetCityList(): Promise<Object> {

        return this.http.get(this.server_url_city_list)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }


    GetWorkFieldList(): Promise<Object> {

        return this.http.get(this.server_url_work_field_list)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }


    GetWorkContractList(): Promise<Object> {
        return this.http.get(this.server_url_work_contract_list)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }

    GetSalaryList(): Promise<Object> {
        return this.http.get(this.server_url_salary_list)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }
    
   


    // TEST LIASON COMPOSANT
    counter: number = 0;
    increment() {
        this.counter++;
    }



    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }


}
