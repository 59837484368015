import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';

import {UiRecruiterModule} from './ui-recruiter/ui-recruiter.module';
import {UiCandidateModule} from './ui-candidate/ui-candidate.module';
import {SharedModule} from './shared/shared.module';
import {CoreModule} from './core/core.module';

//import {AccountCandidateComponent} from './ui-candidate/pg-home/account-candidate/account-candidate.component';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    UiRecruiterModule,
    UiCandidateModule,
    SharedModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
