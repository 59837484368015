


import { Component, OnInit } from '@angular/core';
import { trigger, state, transition, style, animate } from '@angular/animations'

@Component({
   selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
    animations: [
    trigger('visibilityChanged', [
    state('shown', style({ opacity: 1 })),
    state('hidden', style({ opacity: 0 })),
    transition('shown => hidden', animate('2000ms')),
    transition('hidden => shown', animate('2000ms')),
    ])
  ]
})
export class BannerComponent implements OnInit
    {
        visiblityState1:string = 'shown';
       
        i:any = '0'
         constructor(){
            this.toggle();
        }
           ngOnInit() {
  }
      toggle() {
     this.visiblityState1 = 'hidden';
    
setTimeout(()=>{this.visiblityState1 = 'shown'},10000);
setInterval(()=>{this.visiblityState1 = 'hidden';
setTimeout(()=>{this.visiblityState1 = 'shown' },10000);},20000);   
    }

    }
 