import {Component, OnInit} from '@angular/core';
import {PgAuthService} from './pg-auth.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {trigger, state, transition, style, animate} from '@angular/animations'

@Component({
  selector: 'app-pg-auth',
  templateUrl: './pg-auth.component.html',
  styleUrls: ['./pg-auth.component.css'],
  providers: [PgAuthService]

})
export class PgAuthComponent implements OnInit {
  connected: boolean = false;
  deconnected: boolean = true;
  candidate_login: string;
  candidate_password: string;
  connexion_control: FormGroup;
  candidate_session_login: string = "";
  candidate_session: any;
  error_login: boolean = false;
  msg_infos: string = "*Veuillez vérifier votre mot de passe ou login!";


  constructor(private pg_auth_service: PgAuthService, private fb: FormBuilder) {
    this.CreateForm();
    this.GetCandidateSession();
  }

  VerifyCandidateAuth() {
    if (this.connexion_control.status === 'VALID') {
      this.pg_auth_service.VerifyCandidateAuth(this.candidate_login, this.candidate_password).then(result => {
        if (result['result'] === 'OK') {
          //          this.connected = true;
          this.GetCandidateSession();
        } else if (result['result'] === 'KO') {
          this.error_login = true;
          setTimeout(() => {this.error_login = false;}, 4000);
        } else if(result['result'] === 'KO' && parseInt(result['is_active']) === 0){
          this.msg_infos = "Votre compte n'est pas encore activé, un lien d'activation a été fourni dans votre boite e-mail.";
          this.error_login = true;
          setTimeout(() => {this.error_login = false;}, 6000);
        }

      });
    }
  }

  //Check User aloha vo manao opération hafa!!!!
  //CheckCandidate

  GetCandidateSession() {
    this.pg_auth_service.GetCandidateSession().then(result => {
      if (result['result'] === 'OK') {
        this.connected = true;
        this.candidate_session = result['list'][0];
      } else {
        this.connected = false;
      }


    });
  }

  CapitalizeLogin(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  CreateForm() {
    this.connexion_control = this.fb.group({
      login_ctrl: ['', Validators.required],
      password_ctrl: ['', Validators.required]

    });
  }
  FadeInDeconnexion() {
    this.deconnected = false;
    setTimeout(() => {this.deconnected = true}, 5000);
  }
  Deconnexion() {
    this.deconnected = true;
    this.pg_auth_service.DestroyCandidateSession().then(result => {
      if (result['result'] === 'OK') {
        this.connected = false;
      } else {

      }

    });
  }

  Reset() {
    this.candidate_login = "";
    this.candidate_password = "";
    this.connexion_control.reset();
    this.CreateForm();
  }

  //     Reset(): void {
  //    this.connexion_control.reset();
  //        this.showForm = false;
  //        setTimeout(() => {
  //
  //            this.createForm();
  //            this.showForm = true;
  //        });
  //
  //    }


  ngOnInit() {
  }

}
