import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRootComponent } from './core-root/core-root.component';
import { CoreRoutingModule } from './/core-routing.module';


@NgModule({
  imports: [
    CommonModule,
    CoreRoutingModule,

  ],
  declarations: [CoreRootComponent]
})
export class CoreModule { }
