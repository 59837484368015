
import {Component, OnInit} from '@angular/core';
import {AccountCandidateService} from './account-candidate.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {DateAdapter, NativeDateAdapter} from '@angular/material';
@Component({
    selector: 'app-pg-account',
    templateUrl: './pg-account.component.html',
    styleUrls: ['./pg-account.component.css'],
    providers: [AccountCandidateService, DatePipe]
})
export class PgAccountComponent implements OnInit {
    candidate_firstname: string;
    candidate_name: string;
    candidate_login: string;
    candidate_password: string;
    candidate_mail: string;
    candidate_gender: string;
    candidate_birth_date: any;
    country: any;
    candidate_address: string;
    candidate_tel: string;
    candidate_id_country: number;
    confirm_candidate_password: string;
    indication_message: string = "";
    show_message: boolean = false;
    form_account_candidate: FormGroup;
    showForm: boolean = true;
    password_ok: boolean = false;
    country_list : any;
    date_modif: Array<Date> = [];
    
     
    constructor(private account_candidate_service: AccountCandidateService, private fb: FormBuilder, private datepipe: DatePipe, dateAdapter: DateAdapter<NativeDateAdapter>) {
        this.CreateForm();
        dateAdapter.setLocale('fr-FR');
    }

    CreateAccountCandidate() {
        var date_line = (this.datepipe.transform(this.candidate_birth_date, 'dd-MM-yyyy'));
        
        if (this.candidate_password === this.confirm_candidate_password) {

            this.password_ok = true;
        } else {
            this.password_ok = false;
            this.indication_message = 'Les mots de passe ne correspondent pas';
            this.show_message = true;
            setTimeout(() => {this.show_message = false;}, 2000);
        }
        if ((this.form_account_candidate.status === 'VALID') && (this.password_ok)) {
            this.account_candidate_service.CreateAccountCandidate(this.candidate_name, this.candidate_firstname, this.candidate_password, this.candidate_mail, this.candidate_gender, date_line, this.country.id_country, this.candidate_address, this.candidate_tel).then(result => {

                if (result['result'] === 'OK') {
                    this.indication_message = "Veuillez vérifier votre boîte mail et confirmer votre inscription";
                    this.show_message = true;
                    setTimeout(() => {this.show_message = false;}, 7000);
                    this.Reset();
                }

            });
        }


    }
    
    GetCountryList(){
        this.account_candidate_service.GetCountryList().then(result => {
            this.country_list = result['list'];
            });
    }

    CancelCreateAccount() {
        this.Reset();
        this.show_message = false;
    }

    CreateForm() {
        this.form_account_candidate = this.fb.group({
            candidate_name_ctrl: ['', Validators.required],
            candidate_firstname_ctrl: ['', Validators.required],
            candidate_password_ctrl: ['', Validators.required],
            confirm_candidate_password_ctrl: ['', Validators.required],
            candidate_email_ctrl: ['', Validators.required],
            
//            candidate_email_ctrl: ['', Validators.compose([Validators.required, Validators.pattern('^[^\s@]+@[^\s@]+\.[^\s@]{2,}$'), Validators.minLength(1)])],
            candidate_gender_ctrl: ['', Validators.required],
            candidate_birth_date_ctrl: ['', Validators.required],
            candidate_id_country_ctrl: ['', Validators.required],
            candidate_address_ctrl: ['', Validators.required],
            candidate_tel_ctrl: ['', Validators.required]
        });


    }

    Reset(): void {
        this.form_account_candidate.reset();
        this.showForm = false;
        setTimeout(() => {
            this.CreateForm();
            this.showForm = true;
        });
    }



    ngOnInit() {
        this.GetCountryList();
    }

}
