import {Injectable} from '@angular/core';
import {Http} from '@angular/http';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class PgBlogService {
    
    private server_url_get_article_list = './server/server.php?cmd=LIST_ARTICLE';
 private server_url_get_article_by_id = './server/server.php?cmd=GET_ARTICLE_BY_ID';
   constructor(private http: Http) {

    }
    
     GetArticleList(): Promise<Object> {
        return this.http.get(this.server_url_get_article_list)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }
    
      GetArticleById(id_article: string): Promise<Object> {
        return this.http.get(this.server_url_get_article_by_id+'&id_article=' +id_article)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }
    
     private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }

}
