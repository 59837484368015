import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {OfferService} from './../content-offer/offer.service';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ContentOfferComponent} from './../content-offer/content-offer.component';
import {ObjectOffer} from './../object-offer';

@Component({
    selector: 'app-content-search',
    templateUrl: './content-search.component.html',
    styleUrls: ['./content-search.component.css'],
    providers: [ContentOfferComponent]
})
export class ContentSearchComponent implements OnInit, OnChanges {
    display_search_offer: boolean = true;

    display: boolean = false;
    opc1: string = '1';
    opc2: string = '1';
    opc3: string = '1';
    opc4: string = '1';
    opc5: string = '1';
    opc6: string = '1';
    contract_list: any;
    field_list: any;
    city_list: any;
    salary_list: any;
    form_search_offer: FormGroup;
    contract: any;
    field: any;
    city: string = '';
    salary: any;
    start_date: string = '';
    keyword: string = '';
    check_tana: boolean = false;
    check_fianara: boolean = false;
    check_toliary: boolean = false;
    check_diego: boolean = false;
    check_mahajanga: boolean = false;
    check_tamaga: boolean = false;
    default_contract = '1';
    id_city_array = [];
    id_array_city = [1, 2, 3, 4, 5, 6];
    show_form: boolean = true;

    object_list: ObjectOffer = {
        id_work_contract: 0,
        id_work_field: 0,
        array_city: [],
        start_date: '',
        salary: '',
        keyword: ''
    };

    id_city_string = '';
    form_account_candidate: FormGroup;
    constructor(private content_offer_component: ContentOfferComponent, private offer_service: OfferService, private fb: FormBuilder, private router: Router) {

        this.CreateForm();

        this.offer_service.object_filter$.subscribe(
            data => {
                //                console.log('Contenu offre -> Filtrage: ' + data.array_city);
            });

    }
    CreateForm() {
        this.form_search_offer = this.fb.group({
            salary_ctrl: [''],
            start_date_ctrl: [''],
            field_ctrl: [''],
            contract_ctrl: [''],
            city_ctrl: [''],
            date_ctrl: [''],
            keyword_ctrl: ['']

        });

    }

    BuildIdCityImage(checkbox_value: boolean, id_city: number) {
        switch (id_city) {

            case 1:
                {
                    if (this.opc1 == '1') {
                        this.opc1 = '0';
                        this.check_tana = true;
                    }
                    else {
                        this.opc1 = '1';
                        this.check_tana = false;
                    }
                }
                break;
            case 5:
                {
                    if (this.opc5 == '1') {
                        this.opc5 = '0';
                        this.check_diego = true;
                    }
                    else {
                        this.opc5 = '1';
                        this.check_diego = false;
                    }
                }
                break;
            case 6:
                {
                    if (this.opc6 == '1') {
                        this.opc6 = '0';
                        this.check_mahajanga = true;
                    }
                    else {
                        this.opc6 = '1';
                        this.check_mahajanga = false;
                    }
                }
                break;
            case 2:
                {
                    if (this.opc2 == '1') {
                        this.opc2 = '0';
                        this.check_fianara = true;
                    }
                    else {
                        this.opc2 = '1';
                        this.check_fianara = false;
                    }
                }
                break;
            case 4:
                {
                    if (this.opc4 == '1') {
                        this.opc4 = '0';
                        this.check_tamaga = true;
                    }
                    else {
                        this.opc4 = '1';
                        this.check_tamaga = false;
                    }
                }
                break;
            case 3:
                {
                    if (this.opc3 == '1') {
                        this.opc3 = '0';
                        this.check_toliary = true;
                    }
                    else {
                        this.opc3 = '1';
                        this.check_toliary = false;
                    }
                }
                break;
        }
    }



    BuildIdCity(checkbox_value: boolean, id_city: number) {
        switch (id_city) {
            case 1:
                if (checkbox_value) {
                    this.id_array_city[0] = 1;
                    this.opc1 = '1';
                } else {
                    this.id_array_city[0] = 0;
                    this.opc1 = '0';
                }
                break;

            case 2:
                if (checkbox_value) {
                    this.id_array_city[1] = 2;
                    this.opc2 = '1';
                } else {
                    this.id_array_city[1] = 0;
                    this.opc2 = '0';
                }
                break;

            case 3:
                if (checkbox_value) {
                    this.id_array_city[2] = 3;
                    this.opc3 = '1';
                } else {
                    this.id_array_city[2] = 0;
                    this.opc3 = '0';
                }
                break;

            case 4:
                if (checkbox_value) {
                    this.id_array_city[3] = 4;
                    this.opc4 = '1';
                } else {
                    this.id_array_city[3] = 0;
                    this.opc4 = '0';
                }
                break;

            case 5:
                if (checkbox_value) {
                    this.id_array_city[4] = 5;
                    this.opc5 = '1';
                } else {
                    this.id_array_city[4] = 0;
                    this.opc5 = '0';
                }
                break;

            default:
                if (checkbox_value) {
                    this.id_array_city[5] = 6;
                    this.opc6 = '1';
                } else {
                    this.id_array_city[5] = 0;
                    this.opc6 = '0';
                }
                break;


        }

    }

    CopyArray(source: any, destination: any) {
        for (var i = 0; i < source.length; i++) {
            destination[i] = source[i];
        }

        return destination;

    }

    Implode(source: any, destination: string) {
        for (var i = 0; i < source.length; i++) {
            destination = destination + '_' + source[i];
        }
        return destination;
    }

    MakeObjectList() {
        if (this.contract) {
            this.object_list.id_work_contract = this.contract.id_work_contract;
        }
        if (this.field) {
            this.object_list.id_work_field = this.field.id_work_field;
        }
        if (this.start_date) {
            this.object_list.start_date = this.start_date;
        }
        if (this.salary) {
            this.object_list.salary = this.salary.id_salary;
        }
        if (this.keyword) {

            this.object_list.keyword = this.keyword;
        }
        //          this.id_city_string = this.Implode(this.id_array_city, this.id_city_s        tring);
        //        this.object_list.array_city = 'test';

        this.id_city_array = this.CopyArray(this.id_array_city, this.id_city_array);
        this.object_list.array_city = this.id_city_array;

    }


    ReinitObjectList() {
        this.object_list.id_work_contract = 0;
        this.object_list.id_work_field = 0;
        this.object_list.start_date = '';
        this.object_list.salary = '';
        this.object_list.keyword = '';
        //        this.object_list.array_city = this.id_city_array ;
        this.object_list.array_city = [0, 0, 0, 0, 0, 0];
        this.id_array_city = [0, 0, 0, 0, 0, 0];
        //        this.form_search_offer.reset();
        this.show_form = false;
        this.CreateForm();
        this.show_form = true;

    }


    FilterOffer(): void {
        this.MakeObjectList();
        this.offer_service.publishData(this.object_list);


    }

    GetWorkContractList() {

        this.offer_service.GetWorkContractList()
            .then(result => {
                this.contract_list = result['list'];
            });
    }

    GetWorkFieldList() {

        this.offer_service.GetWorkFieldList()
            .then(result => {
                this.field_list = result['list'];
            });
    }

    GetCityList() {

        this.offer_service.GetCityList()
            .then(result => {
                this.city_list = result['list'];
            });
    }

    GetSalaryList() {
        this.offer_service.GetSalaryList()
            .then(result => {
                this.salary_list = result['list'];
            });
    }

    ngOnChanges() {

    }

    ngOnInit() {
        this.GetWorkContractList();
        this.GetWorkFieldList();
        this.GetCityList();
        this.GetSalaryList();

    }

}
