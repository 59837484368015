import { Injectable } from '@angular/core';
import {Http} from '@angular/http';
import 'rxjs/add/operator/toPromise';


@Injectable()
export class PgAuthService {
    private server_url_candidate_auth = './server/server.php?cmd=CANDIDATE_AUTH';
    private server_url_get_candidate_session = './server/server.php?cmd=GET_CANDIDATE_SESSION';
    private server_url_destroy_candidate_session = './server/server.php?cmd=DESTROY_CANDIDATE_SESSION';
    


    constructor(private http: Http) {}

    VerifyCandidateAuth(login: string, password: string): Promise<Object> {
        return this.http.get(this.server_url_candidate_auth + '&login=' + login + '&password=' + password)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);
    }
    
    GetCandidateSession(){
        return this.http.get(this.server_url_get_candidate_session)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError); 
    }
    DestroyCandidateSession(){
         return this.http.get(this.server_url_destroy_candidate_session)
            .toPromise()
            .then(response => response.json() as Object)
            .catch(this.handleError);  
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error);
        return Promise.reject(error.message || error);
    }  
   
    

}
